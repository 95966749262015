<template>
  <div>
    <show-top-agent-self-active-order v-if="this.$store.getters.userRole === 'top_agent'" />
    <show-agency-self-active-order v-else-if="this.$store.getters.userRole === 'agency'" />
    <show-merchant-self-active-order v-else-if="this.$store.getters.userRole === 'merchant'" />
  </div>
</template>

<script>

export default {
  name: 'ShowActiveOrder',
  components: {
    ShowTopAgentSelfActiveOrder: () => import('@/views/agent_monthly_bills/active/top_agent_self/Show'),
    ShowAgencySelfActiveOrder: () => import('@/views/agent_monthly_bills/active/agency_self/Show'),
    ShowMerchantSelfActiveOrder: () => import('@/views/agent_monthly_bills/active/agency_self/Show')
  }
}
</script>
